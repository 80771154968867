<template>
  <div class="animated fadeIn">
    <!-- <h1 class="text-center">Essa página está temporariamente desativada</h1> -->
    <b-row>
      <div class="col-12">
        <b-card>
          <b-row class="mx-md-n3 mx-1">

            <div class="col-sm-6 col-12">
              <b-input-group>
                <b-form-input
                  v-model="filter"
                  @keyup.enter="findAll"
                  placeholder="Procurar por..."
                />
                <b-input-group-append>
                  <b-button size="md" text="Button" variant="outline-info" @click.passive="findAll">
                    <i class="cui-magnifying-glass"></i>
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </div>

            <div class="col-sm-6 col-12 mt-sm-0 mt-3">
              <div v-if="loadingProfiles">
                <loading-animation />
              </div>
              <model-list-select
                v-else
                :list="allProfiles"
                option-value="id"
                option-text="name"
                v-model="selectedProfile"
                placeholder="Selecione o Perfil"
              />
            </div>

          </b-row>
        </b-card>
      </div>
    </b-row>

    <b-row>
      <div class="col-12">
        <div class="card">
          <div class="card-body p-0">
            <div v-if="loadingRows" class="mb-5 pb-5">
              <loading-animation />
            </div>

            <div v-if="!loadingRows && !users.length">
              <div class="row bg-light mx-0 py-3">
                <div class="col text-center">
                  Nenhum Usuário encontrado
                </div>
              </div>
            </div>

            <div v-if="!loadingRows && users.length" class="mb-3">
              <div v-for="(user, index) in users" class="row py-3 border mx-0" :key="index" :class="{ 'bg-light': index % 2 != 0 }">
                <div class="col-auto">
                  <span class="font-weight-bold">Código: </span>{{user.ref}}
                </div>
                <div class="col-sm">
                  <span class="font-weight-bold">Nome: </span>{{user.name}}
                </div>
                <div class="col-sm">
                  <span class="font-weight-bold">Perfil: </span>{{user.profile ? user.profile.name : ''}}
                </div>
                <!-- <div class="col">
                  <span class="font-weight-bold">Email: </span>{{user.email}}
                </div> -->
                <div class="col-sm-auto col-12 mt-sm-0 mt-3">
                  <b-button size="sm" @click.prevent="editItem(index)" :disabled="saving"
                    class="ml-sm-1 ml-0 mr-sm-1 mr-4 mobile-btn-100">
                    <i class="fa fa-edit"></i> Editar
                  </b-button>
                </div>
              </div>
            </div>

            <nav>
              <b-pagination
                v-if="totalRows"
                align="center"
                :limit="10"
                :total-rows="totalRows"
                :per-page="perPage"
                prev-text="Anterior"
                next-text="Próximo"
                v-model="currentPage"
                @change="pageChanged"
                :disabled="loadingRows"/>
            </nav>
          </div>
        </div>
      </div>
    </b-row>

    <b-modal
      centered
      size="md"
      lazy
      :title="'Editar Usuário'"
      header-bg-variant="info"
      footer-border-variant="info"
      v-model="showFormModal">
      <b-container v-if="currentEntity">
        <div v-if="loadingCurrentEntity || saving">
          <loading-animation />
        </div>

        <div v-else>
          <label class="font-weight-bold mr-1">Código: </label>{{currentEntity.ref}}<br>
          <label class="font-weight-bold mr-1">Nome: </label>{{currentEntity.name}}
          <!-- <label class="font-weight-bold mr-1 mt-3">Email: </label>{{currentEntity.email}} -->

          <b-form-group
            label="Perfil"
            class="mt-3"
          >
            <model-list-select
              :list="profiles"
              option-value="id"
              option-text="name"
              v-model="currentEntity.profile"
              placeholder="Digite para pesquisar..."
              @searchchange="searchProfile"
              :isDisabled="saving || !canUserSave"
              :filterPredicate="alwaysTrueFilterPredicate"
            />
          </b-form-group>
        </div>
      </b-container>

      <template v-if="currentEntity" slot="modal-footer" slot-scope="{ ok, cancel }">
        <b-button variant="secondary" @click="closeFormModal">Cancelar</b-button>
        <b-button variant="primary" @click="save"
          :disabled="loadingCurrentEntity || saving || !canUserSave">
          Salvar
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
  import { httpClient } from '@/service'
  import { LoadingAnimation } from '@/components/loaders'
  import { ModelListSelect } from 'vue-search-select'
  import shared from '@/shared/shared'

  export default {
    name: 'users',
    components: { LoadingAnimation, ModelListSelect },

    data () {
      return {
        users: [],
        loadingRows: false,
        currentPage: 1,
        perPage: 10,
        totalRows: null,
        filter: '',
        saving: false,
        editIndex: null,
        showFormModal: false,
        loadingCurrentEntity: false,
        currentEntity: {},
        profiles: [],
        loadingProfiles: false,
        profilesSearchTimeout: null,
        authUser: null,
        canUserSave: false,
        selectedProfile: {},
        allProfiles: []
      }
    },

    mounted: async function () {
      this.$store.commit('app/SET_CURRENT_PAGE', { title: 'Lista de Usuários', size: 4 });
      this.getUser();
      this.createDefaultProfiles();
      this.searchProfile();
    },

    methods: {
      createDefaultProfiles() {
        this.loadingRows = true;
        httpClient.post(`${process.env.VUE_APP_API_URL}profiles/default`, {})
        .then(({ data }) => {
          this.findAll();
        })
        .catch((error) => {
          this.loadingRows = false;
          if (error.message) {
            this.$notify.error(error)
          }
        });
      },

      findAll() {
        let conditions = [{
          field: 'type',
          value: 'U'
        }];

        if (this.filter && this.filter.length) {
          conditions.push({
            logicalOperator: 'OR',
            conditions: [
              {
                field: 'name',
                conditionalOperator: 'LIKE',
                value: this.filter
              },
              /*{
                field: 'email',
                conditionalOperator: 'LIKE',
                value: this.filter
              }*/
              {
                field: 'ref',
                conditionalOperator: 'LIKE',
                value: this.filter
              }
            ]
          });
        }

        if (this.selectedProfile && this.selectedProfile.id) {
          conditions.push({
            field: 'profile.id',
            value: this.selectedProfile.id
          });
        }

        this.loadingRows = true;
        httpClient.post(
          `${process.env.VUE_APP_API_URL}users/select-and-where?page=${this.currentPage - 1}&size=${this.perPage}`, {
          select: ['id', 'ref', 'name'/*, 'email'*/, 'profile.name'],
          where: {
            conditions: conditions,
            orderBies: [
              {
                direction: 'ASC',
                condition: { field: 'ref' }
              },
              {
                direction: 'ASC',
                condition: { field: 'name' }
              }
            ]
          }
        }).then(({ data }) => {
          this.loadingRows = false;
          this.users = data.data.content;
          this.totalRows = data.data.totalElements;
        })
        .catch((error) => {
          this.loadingRows = false;
          if (error.message) {
            this.$notify.error(error)
          }
          this.users = [];
        });
      },

      pageChanged(page) {
        this.currentPage = page;
        this.findAll();
      },

      editItem(index) {
        this.showFormModal = true;
        this.loadingCurrentEntity = true;
        httpClient.get(`${process.env.VUE_APP_API_URL}users/${this.users[index].id}`)
        .then(({ data }) => {
          this.loadingCurrentEntity = false;
          this.editIndex = index;

          if (!this.profiles) {
            this.profiles = [];
          }

          if (data.data.profile) {
            if (!this.profiles.find(p => p.id === data.data.profile.id)) {
              this.profiles.push(data.data.profile);
            }
          } else {
            data.data.profile = {};
          }

          this.currentEntity = data.data;
        })
        .catch((error) => {
          this.loadingCurrentEntity = false;
          if (error.message) {
            this.$notify.error(error)
          }
        });
      },

      closeFormModal() {
        this.editIndex = null;
        this.showFormModal = false;
      },

      save() {
        this.saving = true;
        httpClient.put(`${process.env.VUE_APP_API_URL}users`, this.currentEntity)
        .then(({ data }) => {
          this.saving = false;
          this.$notify.success('Usuário salvo com sucesso');
          this.users[this.editIndex] = data.data;
          this.editIndex = null;
          this.currentEntity = {};
          this.showFormModal = false;
        })
        .catch((error) => {
          this.saving = false;
          if (error.message) {
            this.$notify.error(error)
          }
        });
      },

      alwaysTrueFilterPredicate() {
        return true;
      },

      searchProfile(searchText) {
        if (!this.loadingProfiles) {
          if (this.profilesSearchTimeout) {
            clearTimeout(this.profilesSearchTimeout);
          }

          this.profilesSearchTimeout = setTimeout(() => {
            this.loadingProfiles = true;

            let conditions = [{
              field: 'modules.module.api.name',
              value: 'WMS'
            }];

            if (searchText && searchText.length) {
              conditions.push({
                field: 'name',
                conditionalOperator: 'LIKE_START',
                value: searchText
              });
            }

            httpClient
            .post(`${process.env.VUE_APP_API_URL}profiles/select-and-where?page=${0}&size=${10}`, {
              select: ['id', 'name'],
              where: {
                conditions: conditions,
                groupBy: ['id']
              }
            })
            .then(({ data }) => {
              this.loadingProfiles = false;
              this.profiles = data.data.content;

              if (!this.allProfiles || !this.allProfiles.length) {
                this.allProfiles = data.data.content;
              }

              if (!this.profiles) {
                this.profiles = [];
              }

              if (this.currentEntity
                && this.currentEntity.profile
                && this.currentEntity.profile.id
                && !this.profiles.find(p => p.id === this.currentEntity.profile.id)) {
                this.profiles.push(this.currentEntity.profile);
              }
            })
            .catch((error) => {
              this.loadingProfiles = false
              if (error.message) {
                this.$notify.error(error)
              }
            });
          }, 500);
        }
      },

      getUser() {
        this.authUser = shared.getLocalStorageObj('auth/user');

        if (this.authUser && this.authUser.profile && this.authUser.profile.modules) {
          let usersProfileModule = this.authUser.profile.modules.find(pm => {
            return pm.name && pm.name.toString().toUpperCase() == "USERS"
          });

          this.canUserSave = usersProfileModule && usersProfileModule.canWrite;
        }
      }
    },

    watch: {
      'selectedProfile': function(newVal, oldVal) {
        if (!shared.entitiesAreEqual(newVal, oldVal)) {
          this.findAll();
        }
      }
    }
  }
</script>

<style scoped>

</style>
